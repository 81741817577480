import * as React from 'react';

import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  Datagrid,
  TextField,
  DateField,
  BooleanInput,
  required,
} from 'react-admin';

export const CredentialCategoryList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

export const CredentialCategoryCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  </Create>
);

export const CredentialCategoryEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  </Edit>
);
