import * as React from 'react';

import {
  List,
  Create,
  Edit,
  Filter,
  SimpleForm,
  TextInput,
  ReferenceField,
  Datagrid,
  TextField,
  DateField,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  SelectInput,
} from 'react-admin';

export const CredentialFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by name" source="name" alwaysOn />
  </Filter>
);

export const CredentialList = (props) => (
  <List {...props} filters={<CredentialFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="namespace" />
      <TextField source="expirationFrequency" />
      <TextField source="walletSchema" />
      <ReferenceField
        label="Supplier"
        source="supplierId"
        reference="suppliers"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Credential Category"
        source="credentialCategoryId"
        reference="credentialCategories"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Credential Group"
        source="credentialGroupId"
        reference="credentialGroups"
      >
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

export const CredentialCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <ReferenceInput
        label="Supplier"
        source="supplierId"
        reference="suppliers"
        filterToQuery={(searchText) => ({ name: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" validate={required()} />
      <TextInput source="description" validate={required()} />
      <TextInput source="namespace" validate={required()} />
      <SelectInput
        allowEmpty
        source="expirationFrequency"
        choices={[
          { id: 'Annually', name: 'Annually' },
          { id: 'Every 5 Years', name: 'Every 5 Years' },
          { id: 'Every 10 Years', name: 'Every 10 Years' },
          { id: 'On Request', name: 'On Request' },
          { id: 'On Time', name: 'On Time' },
          { id: 'One Time', name: 'One Time' },
          { id: 'Ongoing', name: 'Ongoing' },
        ]}
      />
      <TextInput source="walletSchema" validate={required()} />
      <ReferenceInput
        label="Credential Group"
        source="credentialGroupId"
        reference="credentialGroups"
        filterToQuery={(searchText) => ({ name: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>

      <ReferenceInput
        label="Credential Category"
        source="credentialCategoryId"
        reference="credentialCategories"
        filterToQuery={(searchText) => ({ name: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const CredentialEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <ReferenceInput
        label="Supplier"
        source="supplierId"
        reference="suppliers"
        filterToQuery={(searchText) => ({ name: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" validate={required()} />
      <TextInput source="description" validate={required()} />
      <TextInput source="namespace" validate={required()} />
      <SelectInput
        allowEmpty
        source="expirationFrequency"
        choices={[
          { id: 'Annually', name: 'Annually' },
          { id: 'Every 5 Years', name: 'Every 5 Years' },
          { id: 'Every 10 Years', name: 'Every 10 Years' },
          { id: 'On Request', name: 'On Request' },
          { id: 'On Time', name: 'On Time' },
          { id: 'One Time', name: 'One Time' },
          { id: 'Ongoing', name: 'Ongoing' },
        ]}
      />
      <TextInput source="walletSchema" validate={required()} />
      <ReferenceInput
        label="Credential Group"
        source="credentialGroupId"
        reference="credentialGroups"
        filterToQuery={(searchText) => ({ name: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>

      <ReferenceInput
        label="Credential Category"
        source="credentialCategoryId"
        reference="credentialCategories"
        filterToQuery={(searchText) => ({ name: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
